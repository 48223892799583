@tailwind components;

@layer components {
  .navbar-nav {
    @apply fixed top-0 z-[120] w-full bg-primary
  }
  .navbar-inner-wrapper {
    @apply max-w-screen-xl flex flex-wrap items-center justify-between mx-auto px-4 py-[10px] ;
  }
  .navbar-brand {
    @apply self-center font-semibold whitespace-nowrap text-white;
  }
  .navbar-list{
    @apply flex relative z-[120] 
  }
  .navbar-dropdown-menu .navbar-list{
    @apply dark:bg-darkThemeBg border-t-[1px] border-gray-200/30
  }
  .navbar-li{
    @apply text-sm px-0 border-b-[1px] border-gray-200/30  bg-primary text-white
  }
  .navbar-link {
    @apply text-lg duration-200 flex items-center py-3 px-3 pl-[17px] rounded md:border-0  text-white;
  }
  .navbar-toggle-btn {
    @apply inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600;
  }
  .navbar-dropdown-menu{
    @apply w-full sm:w-[300px] sm:absolute sm:right-0 bg-white sm:border-l-[1px] sm:border-gray-200/30 sm:dark:border-gray-700 sm:rounded-bl-md;
  }
  .navbar-avatar{
    overflow: visible !important;
  }
  .navbar-avatar img{
    border-radius: 100%;
    z-index: 100;
  }
  .navbar-avatar.has-ring::before{
    content: "";
    position: absolute;
    width: 115%;
    height: 115%;
    border-radius: 100%;
    z-index: 99;
    animation: animateRing1 4s infinite;
    opacity: 1;
    background-color: #fffbb9;
    background-image: linear-gradient(-45deg, transparent, transparent 50%, #F2E82D 50%, #F2E82D);
    background-size: 6px 6px;
  }
  .navbar-avatar.has-2-rings::after{
    content: "";
    position: absolute;
    width: 130%;
    height: 130%;
    border-radius: 100%;
    z-index: 98;
    animation: animateRing2 4s infinite;
    background-color: #86def2;
    background-image: linear-gradient(-45deg, transparent, transparent 50%, #B9F2FF 50%, #B9F2FF);
    background-size: 6px 6px;
  }
  .star-pulse-and-shine-animation{
    animation: starShine 4s infinite, starPulse 4s infinite;
  }
  .star-pulse-animation{
    animation: starPulse 4s infinite
  }
}


@keyframes animateRing1 {
  0%{   transform: scale(1)}
  50%{   transform: scale(1.1) rotate(-360deg);}
  100%{  transform: scale(1) rotate(360deg);   background-color: #fffbb9;
    background-image: linear-gradient(-45deg, transparent, transparent 50%, #F2E82D 50%, #F2E82D);
    background-size: 6px 6px;}
}


@keyframes animateRing2 {
  0%{   transform: scale(1)}
  50%{   transform: scale(1.1) rotate(360deg);  }
  100%{  transform: scale(1) rotate(-360deg);   background-color: #86def2;
    background-image: linear-gradient(-45deg, transparent, transparent 50%, #B9F2FF 50%, #B9F2FF);
    background-size: 6px 6px;}
}


@keyframes starShine {
  0%{color: gold; stroke: white}
  50%{color: rgb(247, 228, 117); stroke: white; stroke-width: 0.5px; }
  100%{color: gold; stroke: white}
}
@keyframes starPulse {
  0%{transform: scale(1);}
  50%{transform: scale(1.10);}
  100%{transform: scale(1);}
}


@keyframes starShineHard {
  0%{color: white; stroke: white}
  50%{color: rgb(255, 245, 187); stroke: white; stroke-width: 0.5px; }
  100%{color: white; stroke: white}
}
@keyframes starPulseHard {
  0%{transform: scale(1);}
  50%{transform: scale(1.30);}
  100%{transform: scale(1);}
}

.star-pulse-and-shine-hard{
  animation: starShineHard 3s infinite, starPulseHard 3s infinite;
}