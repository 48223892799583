@tailwind base;
@tailwind components;
@tailwind utilities;


@import './components/navbar/navbar.css';
@import './components/tabsbar/tabsbar.css';
@import './pages/scores/scores.css';
@import './pages/task/taskCreate.css';
@import './pages/admin/dashboard.css';
@import './pages/feed/filters/filtersBar.css';
@import './pages/activity/activityPage.css';
@import './pages/settings/settings.css';
@import './components/task/card/taskCard.css';
@import './components/task/player/taskStepper.css';
@import './components/task/player/slide/slide.css';
@import './components/task/player/audio/transcriptor/transcriptor.css';
@import './components/task/player/audio/synthesizer/synthesizer.css';
@import './components/task/player/audio/audio_player/audio_player.css';
@import './components/task/player/slide/_animations/fireworks.css';
@import './components/task/player/slide/_animations/timer.css';
@import './components/task/player/slide/reading/components/assign_option/sr_assign_option.css';
@import './components/task/player/slide/writing/SW.css';
@import './components/task/player/slide/reading/components/missing_sentences/sr_missing_sentences.css';
@import './components/task/player/animations/starswonanimation.css';

*{
  -webkit-tap-highlight-color: transparent;
}

html, body, #root{
  @apply h-full
}

input[type="radio"]{
  @apply accent-secondary
}

@layer components {
  .app {
    @apply  bg-white dark:bg-black;
  }
  .icon{
    width: 20px !important;
    height: 20px !important;
    margin-right: 7px;
  }
  .fade-in{
    animation: fadeIn 2s forwards;
  }
  .menu-fade-in{
    animation: fadeIn 0.3s forwards;
  }
}


/* FONTS */
@font-face {
  font-family: 'bricolage';
  src: local('bricolage'), url("./fonts/bricolage_grotesque/static/BricolageGrotesque-Regular.ttf") format('truetype');
}
.font-bricolage{
  font-family: 'bricolage';
}

/* General styles */
.white-text-with-shadow{
  @apply [text-shadow:_1.5px_1.5px_2px_rgb(0_0_0/_90%)] text-white
}

.see-final-grade-btn{
  @apply !bg-primary
}


/* ANIMATIONS */
@keyframes fadeIn {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}
